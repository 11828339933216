@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Avenir';
  src: url('/assets/avenir_ff/AvenirLTStd-Black.otf') format('opentype');
}

.work-sans {
  font-family: 'Work Sans', sans-serif;
}

html,
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-gray-700;
  @apply w-full;
  @apply h-full;
}

#root {
  @apply h-full;
}

.forminput {
  @apply w-full;
  @apply p-3;
  @apply border;
  @apply border-gray-200;
  @apply rounded-lg;
}

.page-header-bg {
  background: url('./assets/images/faq-bg.svg');
}

.Toastify__toast-container {
  z-index: 200000;
}
